import {Input} from '@nextui-org/react';
import {ReactNode} from 'react';

type CityIDInputProps = {
  value?: string | (readonly string[] & string) | undefined;
  onValueChange?: ((value: string) => void) | undefined;
  placeholder?: string | undefined;
  labelPlacement?: 'inside' | 'outside' | 'outside-left' | undefined;
  startContent?: ReactNode;
  onAccept?: (value: string) => void | undefined;
  autoFocus?: boolean | undefined;
  className?: string | undefined;
};

export default function CityIDInput(
  props: CityIDInputProps = {
    value: undefined,
    onValueChange: undefined,
    placeholder: undefined,
    labelPlacement: undefined,
    startContent: undefined,
    onAccept: undefined,
    autoFocus: undefined,
    className: undefined,
  }
) {
  const onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement> | KeyboardEvent
  ) => {
    if (e.key === 'Enter') {
      props.onAccept?.(props.value ?? '');
    }
  };

  return (
    <Input
      className={props.className}
      type="search"
      radius="none"
      variant="bordered"
      placeholder={props.placeholder}
      value={props.value}
      labelPlacement={props.labelPlacement}
      startContent={props.startContent}
      onValueChange={props.onValueChange}
      onKeyDown={e => onKeyDown(e)}
      autoFocus={props.autoFocus}
      classNames={{
        inputWrapper: ['bg-white', 'dark:bg-black', 'border-primary'],
      }}
    />
  );
}
