import {config} from '@/config';
import AWSAppSyncClient from 'aws-appsync';
import {
  AssociateClientWithResourceServerInput,
  Client,
  CreateClientResult,
  CreateResourceServerScopeInput,
  GetClientResourceServersInput,
  ResourceServer,
  Team,
  TeamClient,
  UpdateClientInput,
  UpdateResourceServerInput,
} from '@shared/api/graphql';
import {gql} from '@apollo/client';
import * as queries from '@shared/api/queries';
import * as mutations from '@shared/api/mutations';
import * as userSessionRepo from '../repositories/user-session-repo';
import {parseError} from './util';

const createAPIClient = () =>
  new AWSAppSyncClient({
    url: config.apiUrl,
    region: config.awsRegion,
    auth: {
      type: 'AMAZON_COGNITO_USER_POOLS',
      jwtToken: userSessionRepo.getAccessToken()!,
    },
    disableOffline: true,
  });

export const query = async <InputType, OutputType>(
  query: string & {
    __generatedQueryInput: InputType;
    __generatedQueryOutput: OutputType;
  },
  input: InputType
): Promise<OutputType> => {
  try {
    const client = createAPIClient();

    const result = await client.query({
      query: gql(query),
      variables: input,
    });

    const data = result.data as OutputType;
    return data;
  } catch (e) {
    throw parseError(e);
  }
};

export const mutation = async <InputType, OutputType>(
  mutation: string & {
    __generatedMutationInput: InputType;
    __generatedMutationOutput: OutputType;
  },
  input: InputType
): Promise<OutputType> => {
  try {
    const client = createAPIClient();

    const result = await client.mutate({
      mutation: gql(mutation),
      variables: input,
    });

    const data = result.data as OutputType;
    return data;
  } catch (e) {
    throw parseError(e);
  }
};

export const getAllTeams = async (): Promise<Team[]> => {
  return (await query(queries.getAllTeams, {})).getAllTeams;
};

export const getAllClients = async (): Promise<TeamClient[]> => {
  return (await query(queries.getAllClients, {})).getAllClients;
};

export const getTeamById = async (
  teamId: string
): Promise<Team | null | undefined> => {
  return (
    await query(queries.getTeam, {
      input: {
        teamId,
      },
    })
  ).getTeam;
};

export const getClientResourceServers = async (
  input: GetClientResourceServersInput
): Promise<Client> => {
  return (
    await query(queries.getClientResourceServers, {
      input,
    })
  ).getClientResourceServers;
};

export const createResourceServer = async (
  teamId: string,
  name: string,
  identifier: string,
  scopes: CreateResourceServerScopeInput[]
): Promise<ResourceServer> => {
  return (
    await mutation(mutations.createResourceServer, {
      input: {
        teamId,
        name,
        identifier,
        scopes: scopes.map(
          (scope): CreateResourceServerScopeInput => ({
            description: scope.description,
            name: scope.name,
          })
        ),
      },
    })
  ).createResourceServer;
};

export const updateResourceServer = async (
  input: UpdateResourceServerInput
): Promise<void> => {
  (
    await mutation(mutations.updateResourceServer, {
      input,
    })
  ).updateResourceServer;
};

export const createClient = async (
  teamId: string,
  name: string
): Promise<CreateClientResult> => {
  return (
    await mutation(mutations.createClient, {
      input: {
        teamId,
        name,
      },
    })
  ).createClient;
};

export const updateClient = async (
  input: UpdateClientInput
): Promise<Client> => {
  return (
    await mutation(mutations.updateClient, {
      input,
    })
  ).updateClient;
};

export const associateClientWithResourceServer = async (
  input: AssociateClientWithResourceServerInput
): Promise<Client> => {
  return (
    await mutation(mutations.associateClientWithResourceServer, {
      input,
    })
  ).associateClientWithResourceServer;
};
