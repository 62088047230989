export const parseError = (e: unknown): string => {
  if (e instanceof Error) {
    let message = e.message;
    if (message.startsWith('GraphQL error: ')) {
      message = message.replace('GraphQL error: ', '');
    }
    if (message.startsWith('Error: ')) {
      message = message.replace('Error: ', '');
    }

    try {
      const parsedError = JSON.parse(message);
      if (parsedError.body) {
        return formatErrorMessage(parsedError.body);
      }
      return JSON.stringify(parsedError);
    } catch {
      return message;
    }
  }
  return String(e);
};

const formatErrorMessage = (errorBody: string) => {
  const bodyContent = errorBody.replace(/^"|"$/g, '');
  const parts = bodyContent.split('Validation Result:');
  const errorMessage = parts[0].trim();
  const validationPart = parts[1] ? 'Validation Result: ' + parts[1] : '';
  const formattedMessage = `${errorMessage}\n${validationPart}`
    .replace(/Keyword:/g, 'Keyword: ')
    .replace(/Path:/g, '\nPath: ')
    .replace(/Message:/g, '\nMessage: ')
    .replace(/\\t/g, '\n')
    .trim();

  return formattedMessage;
};
