/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from './graphql';
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createResourceServer =
  /* GraphQL */ `mutation CreateResourceServer($input: CreateResourceServerInput!) {
  createResourceServer(input: $input) {
    id
    name
    identifier
    scopes {
      id
      name
      description
      active
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateResourceServerMutationVariables,
    APITypes.CreateResourceServerMutation
  >;
export const updateResourceServer =
  /* GraphQL */ `mutation UpdateResourceServer($input: UpdateResourceServerInput!) {
  updateResourceServer(input: $input) {
    id
    name
    identifier
    scopes {
      id
      name
      description
      active
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateResourceServerMutationVariables,
    APITypes.UpdateResourceServerMutation
  >;
export const createClient =
  /* GraphQL */ `mutation CreateClient($input: CreateClientInput!) {
  createClient(input: $input) {
    clientUUID
    clientID
    clientName
    secret
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateClientMutationVariables,
    APITypes.CreateClientMutation
  >;
export const updateClient =
  /* GraphQL */ `mutation UpdateClient($input: UpdateClientInput!) {
  updateClient(input: $input) {
    clientUUID
    clientID
    clientName
    teamName
    teamID
    servers {
      resourceID
      resourceName
      teamID
      teamName
      scopes {
        active
        scopeDescription
        scopeID
        scopeName
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateClientMutationVariables,
    APITypes.UpdateClientMutation
  >;
export const associateClientWithResourceServer =
  /* GraphQL */ `mutation AssociateClientWithResourceServer(
  $input: AssociateClientWithResourceServerInput!
) {
  associateClientWithResourceServer(input: $input) {
    clientUUID
    clientID
    clientName
    teamName
    teamID
    servers {
      resourceID
      resourceName
      teamID
      teamName
      scopes {
        active
        scopeDescription
        scopeID
        scopeName
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.AssociateClientWithResourceServerMutationVariables,
    APITypes.AssociateClientWithResourceServerMutation
  >;
