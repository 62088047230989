/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from './graphql';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getAllTeams = /* GraphQL */ `query GetAllTeams {
  getAllTeams {
    id
    name
    resourceServers {
      id
      name
      identifier
      scopes {
        id
        name
        description
        active
        __typename
      }
      __typename
    }
    clients {
      clientUUID
      clientID
      clientName
      teamName
      teamID
      servers {
        resourceID
        resourceName
        teamID
        teamName
        scopes {
          active
          scopeDescription
          scopeID
          scopeName
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllTeamsQueryVariables,
  APITypes.GetAllTeamsQuery
>;
export const getAllClients = /* GraphQL */ `query GetAllClients {
  getAllClients {
    uuid
    id
    name
    teamId
    teamName
    servers {
      id
      name
      identifier
      scopes {
        id
        name
        description
        active
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllClientsQueryVariables,
  APITypes.GetAllClientsQuery
>;
export const getTeam = /* GraphQL */ `query GetTeam($input: GetTeamInput) {
  getTeam(input: $input) {
    id
    name
    resourceServers {
      id
      name
      identifier
      scopes {
        id
        name
        description
        active
        __typename
      }
      __typename
    }
    clients {
      clientUUID
      clientID
      clientName
      teamName
      teamID
      servers {
        resourceID
        resourceName
        teamID
        teamName
        scopes {
          active
          scopeDescription
          scopeID
          scopeName
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTeamQueryVariables, APITypes.GetTeamQuery>;
export const getClientResourceServers =
  /* GraphQL */ `query GetClientResourceServers($input: GetClientResourceServersInput) {
  getClientResourceServers(input: $input) {
    clientUUID
    clientID
    clientName
    teamName
    teamID
    servers {
      resourceID
      resourceName
      teamID
      teamName
      scopes {
        active
        scopeDescription
        scopeID
        scopeName
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetClientResourceServersQueryVariables,
    APITypes.GetClientResourceServersQuery
  >;
